import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const verifyEmailUrl = "/user/verifyEmail/";

const verifyEmail = async (token: string, reCaptchaToken: any) => {
  const config = {
    headers: { "g-recaptcha-response": reCaptchaToken },
  };
  const response = await axios.post(
    `${baseUrl}${verifyEmailUrl}${token}`,
    null,
    config
  );
  return response.data;
};

const registerService = { verifyEmail };

export default registerService;
