import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./ProvideAuth";

// login screen  if you're not yet authenticated.
function PrivateRoute(props: any) {
  const { children, ...rest } = props;
  let { auth } = useAuth();
  // console.log(auth);
  // console.log("rest", rest);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return auth && auth.authToken ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
export default PrivateRoute;
