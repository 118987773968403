import "./DashboardHeading.scss";
const DashboardHeading = () => {
  return (
    <section className="dashboard-heading">
      <div className="title-container">
        <h1>Activity Preferences</h1>
      </div>
    </section>
  );
};

export default DashboardHeading;
