import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";

import "./AboutUsContent.scss";
const AboutUsContent = () => {
  return (
    <main className="about-us">
      <section className="about-us-header">
        <h3>About Us</h3>
      </section>
      <section className="about-us-body">
        <div className="content-ct">
          <p>
            THE GLOBAL RESEARCH COMMUNITY (TGRC) is one of the fastest growing
            digital research, data and insights gathering communities, providing
            the Pharmaceutical Industry with data and insights into critical
            questions that will help shape and direct therapeutics development
            now and in the future.
          </p>

          <p>
            By registering and becoming a member of the Community your profile
            will be matched with research opportunities sponsored by the top 20
            global pharmaceutical companies. These will include Digital Advisory
            Boards, Steering Committees, Market Research, and Clinical Trials
            Recruitment.
          </p>
          <p>
            In return for your participation you will be paid an Honorarium at a
            rate based on local guidelines.
          </p>
          <p>
            The Global Research Community is Part of Interactive Pharma
            Solutions.
          </p>
          <a
            href="https://www.interactivepharma.com"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              type="button"
              size="size-xxlg"
              colorTheme="has-primary-theme"
            >
              Visit Interactive Pharma Website
            </Button>
          </a>
        </div>
      </section>
    </main>
  );
};

export default AboutUsContent;
