import React, { ReactNode } from "react";
import "./OpportunityIcon.scss";
interface OpportunityIconType {
  opptitle: string;
  children: ReactNode;
}
const OpportunityIcon = ({ opptitle, children }: OpportunityIconType) => {
  return (
    <div className="opp-icon">
      <div className="icon-container">{children}</div>
      <h3>{opptitle}</h3>
    </div>
  );
};

export default OpportunityIcon;
