import * as Yup from "yup";
// Supported File Formats
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
export const registrationValidationSchema = Yup.object({
  profileImage: Yup.mixed()
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 2 * 1024 * 1024)
    )
    .test(
      "FILE_FORMAT",
      "Uploaded file has unsupported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    ),
  fullName: Yup.string().required("Full Name is required"),
  seniority: Yup.string().required("Seniority needs to be selected"),
  speciality: Yup.string().required("Speciality needs to be selected"),
  region: Yup.string().required("Region needs to be selected"),
  professionalNumber: Yup.string().required("Professional Number is Required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is Required"),
  mobileNumber: Yup.string()
    .test("len", "Please enter mobile number with country code", (val) =>
      val ? val.length >= 12 : false
    )
    .required("Mobile number is required"),
  password: Yup.string()
    .trim()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])((?=.*?[0-9])|(?=.*?[#?!@$%^&*-])).{9,}$/,
      "Password criteria:\n 1. Have at least 9 characters\n 2. Contains mixed case letters\n 3. Contain at least 1 number OR a special character.\n 4. Allowed characters are: a-z, A-Z, 0-9 and !@#$*^().,{}[]~-"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
    .required("Confirm password is required"),
  compliance: Yup.array().test(
    "len",
    "Please accept all conditions",
    (val) => val?.length === 3
  ),
});
