import { useHistory } from "react-router-dom";
import Button from "../../../../../components/Button";
import { useProvideAuth } from "../../../../../navigation/Auth/useProvideAuth";
import { OPPORTUNITY, ROOT } from "../../../../../navigation/CONSTANT";
import "./OTMenu.scss";

const OTMenu = () => {
  const history = useHistory();
  const { signout } = useProvideAuth();

  const goToOpp = () => {
    history.push(OPPORTUNITY);
  };

  const handleLogOut = async () => {
    await signout();
  };
  return (
    <div className="ot-menu">
      <h3 className="ot-menu-title">Your preferences have been saved </h3>
      <p>We will be in touch with opportunities that match your profile.</p>

      <Button
        type="button"
        size="size-xxlg"
        colorTheme="has-primary-theme"
        onClick={goToOpp}
      >
        Back to Opportunities
      </Button>
      <Button
        type="button"
        size="size-xxlg"
        colorTheme="has-primary-theme"
        onClick={handleLogOut}
      >
        Log Out
      </Button>
    </div>
  );
};

export default OTMenu;
