import "./Form.scss";
import CheckboxGroup from "./CheckboxGroup";
import Input from "./Input";
import Select from "./Select";
import Password from "./Password";

const FormikControl = (props: any) => {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "password":
      return <Password {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "checkbox":
      return <CheckboxGroup {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
