export const specialityTypes = [
  {
    value: "spec0",
    key: "Acute Internal Medicine",
  },
  {
    value: "spec1",
    key: "Acute Internal Medicine + Critical Care/ITU (dual accreditation)",
  },
  {
    value: "spec2",
    key: "Acute Internal Medicine + General (Internal) Medicine (dual accreditation)",
  },
  {
    value: "spec3",
    key: "Aesthetic medicine",
  },
  {
    value: "spec4",
    key: "Allergy",
  },
  {
    value: "spec5",
    key: "Anaesthetics",
  },
  {
    value: "spec6",
    key: "Anaesthetics and ITU (Dual accreditation)",
  },
  {
    value: "spec7",
    key: "Audiovestibular Medicine",
  },
  {
    value: "spec8",
    key: "Aviation and Space Medicine",
  },
  {
    value: "spec9",
    key: "Cardiology",
  },
  {
    value: "spec10",
    key: "Cardiology + General (Internal) Medicine (dual accreditation)",
  },
  {
    value: "spec11",
    key: "Cardiothoracic surgery",
  },
  {
    value: "spec12",
    key: "Chemical Pathology",
  },
  {
    value: "spec13",
    key: "Child and Adolescent + Learning Disability Psychiatry (dual accreditation)",
  },
  {
    value: "spec14",
    key: "Child and Adolescent Psychiatry",
  },
  {
    value: "spec15",
    key: "Child and Adolescent Psychiatry + Psychotherapy (dual accreditation)",
  },
  {
    value: "spec16",
    key: "Civil Service",
  },
  {
    value: "spec17",
    key: "Clinical Genetics",
  },
  {
    value: "spec18",
    key: "Clinical Neurophysiology",
  },
  {
    value: "spec19",
    key: "Clinical Oncology",
  },
  {
    value: "spec20",
    key: "Clinical Pharmacology and Therapeutics",
  },
  {
    value: "spec21",
    key: "Colorectal surgery",
  },
  {
    value: "spec22",
    key: "Dermatology",
  },
  {
    value: "spec23",
    key: "Diabetes",
  },
  {
    value: "spec24",
    key: "Diagnostic neuropathology",
  },
  {
    value: "spec25",
    key: "Emergency Medicine",
  },
  {
    value: "spec26",
    key: "Emergency Medicine + Critical/Intensive Care Medicine (dual accreditation)",
  },
  {
    value: "spec27",
    key: "Endocrinology + General (Internal) Medicine (dual accreditation)",
  },
  {
    value: "spec28",
    key: "Endocrinology and Diabetes Mellitus",
  },
  {
    value: "spec29",
    key: "Experimental Medicine",
  },
  {
    value: "spec30",
    key: "Forensic + Adolescent Psychiatry (dual accreditation)",
  },
  {
    value: "spec31",
    key: "Forensic + Learning Disability Psychiatry (dual accreditation)",
  },
  {
    value: "spec32",
    key: "Forensic histopathology",
  },
  {
    value: "spec33",
    key: "Forensic Medicine",
  },
  {
    value: "spec34",
    key: "Forensic Psychiatry",
  },
  {
    value: "spec35",
    key: "Forensic Psychiatry + Psychotherapy (dual accreditation)",
  },
  {
    value: "spec36",
    key: "Gastroenterology",
  },
  {
    value: "spec37",
    key: "Gastroenterology + General (Internal) Medicine (dual accreditation)",
  },
  {
    value: "spec38",
    key: "General (Internal) Medicine",
  },
  {
    value: "spec39",
    key: "General and Old age psychiatry (dual accreditation)",
  },
  {
    value: "spec40",
    key: "General Practice",
  },
  {
    value: "spec41",
    key: "General Psychiatry",
  },
  {
    value: "spec42",
    key: "General surgery",
  },
  {
    value: "spec43",
    key: "Genitourinary medicine and Sexual Health",
  },
  {
    value: "spec44",
    key: "Geriatric + General (Internal) Medicine (dual accreditation)",
  },
  {
    value: "spec45",
    key: "Geriatric medicine",
  },
  {
    value: "spec46",
    key: "Haematology",
  },
  {
    value: "spec47",
    key: "Haemorrhoidal surgery",
  },
  {
    value: "spec48",
    key: "Health Informatics",
  },
  {
    value: "spec49",
    key: "Health research",
  },
  {
    value: "spec50",
    key: "Histopathology",
  },
  {
    value: "spec51",
    key: "Immunology",
  },
  {
    value: "spec52",
    key: "Immunology + General (Internal) Medicine (dual accreditation)",
  },
  {
    value: "spec53",
    key: "Infectious diseases",
  },
  {
    value: "spec54",
    key: "Intensive/Critical Care",
  },
  {
    value: "spec55",
    key: "Intensive/Critical Care Medicine + General (Internal) Medicine (dual\n      accreditation)",
  },
  {
    value: "spec56",
    key: "Judicial",
  },
  {
    value: "spec57",
    key: "Leadership and Management",
  },
  {
    value: "spec58",
    key: "Medical devices",
  },
  {
    value: "spec59",
    key: "Medical education",
  },
  {
    value: "spec60",
    key: "Medical Management",
  },
  {
    value: "spec61",
    key: "Medical Microbiology and Virology",
  },
  {
    value: "spec62",
    key: "Medical Oncology",
  },
  {
    value: "spec63",
    key: "Medical Ophthalmology",
  },
  {
    value: "spec64",
    key: "Medical Psychotherapy",
  },
  {
    value: "spec65",
    key: "Men's sexual health",
  },
  {
    value: "spec66",
    key: "Nephrology + General (Internal) Medicine (dual accreditation)",
  },
  {
    value: "spec67",
    key: "Nephrology and intensive/critical care medicine (dual accreditation)",
  },
  {
    value: "spec68",
    key: "Neurology",
  },
  {
    value: "spec69",
    key: "Neurology + General (Internal) Medicine (dual accreditation)",
  },
  {
    value: "spec70",
    key: "Neurology and clinical neurophysiology (dual accreditation)",
  },
  {
    value: "spec71",
    key: "Neuropathology",
  },
  {
    value: "spec72",
    key: "Neuropsychiatry",
  },
  {
    value: "spec73",
    key: "Neurosurgery",
  },
  {
    value: "spec74",
    key: "Nuclear Medicine",
  },
  {
    value: "spec75",
    key: "Obstetrics and Gynaecology",
  },
  {
    value: "spec76",
    key: "Occupational medicine",
  },
  {
    value: "spec77",
    key: "Old Age Psychiatry",
  },
  {
    value: "spec78",
    key: "Oncology",
  },
  {
    value: "spec79",
    key: "Ophthalmology",
  },
  {
    value: "spec80",
    key: "Oral and Maxillofacial Surgery",
  },
  {
    value: "spec81",
    key: "Oral medicine",
  },
  {
    value: "spec82",
    key: "Orthopaedic and Trauma Surgery",
  },
  {
    value: "spec83",
    key: "Otolaryngology (ENT)",
  },
  {
    value: "spec84",
    key: "Paediatric and perinatal pathology",
  },
  {
    value: "spec85",
    key: "Paediatric Cardiology",
  },
  {
    value: "spec86",
    key: "Paediatric surgery",
  },
  {
    value: "spec87",
    key: "Paediatrics",
  },
  {
    value: "spec88",
    key: "Palliative medicine",
  },
  {
    value: "spec89",
    key: "Pathology",
  },
  {
    value: "spec90",
    key: "Pharmaceutical medicine",
  },
  {
    value: "spec91",
    key: "Plastic surgery",
  },
  {
    value: "spec92",
    key: "Psychiatry of Learning Disability",
  },
  {
    value: "spec93",
    key: "Public Health and Health protection",
  },
  {
    value: "spec94",
    key: "Radiology",
  },
  {
    value: "spec95",
    key: "Rehabilitation medicine",
  },
  {
    value: "spec96",
    key: "Renal medicine",
  },
  {
    value: "spec97",
    key: "Respiratory + Critical/Intensive Care Medicine (dual accreditation)",
  },
  {
    value: "spec98",
    key: "Respiratory + General (Internal) Medicine (dual accreditation)",
  },
  {
    value: "spec99",
    key: "Respiratory Medicine",
  },
  {
    value: "spec100",
    key: "Rheumatology",
  },
  {
    value: "spec101",
    key: "Rheumatology + General (Internal) Medicine (dual accreditation)",
  },
  {
    value: "spec102",
    key: "Sexual and Reproductive Health",
  },
  {
    value: "spec103",
    key: "Sports and exercise medicine",
  },
  {
    value: "spec104",
    key: "Toxicology",
  },
  {
    value: "spec105",
    key: "Tropical Medicine",
  },
  {
    value: "spec106",
    key: "Upper GI surgery",
  },
  {
    value: "spec107",
    key: "Urology",
  },
  {
    value: "spec108",
    key: "Vascular and Transplant Surgery",
  },
  {
    value: "spec109",
    key: "Vascular surgery",
  },
];
