import "./EmailVerificationInfo.scss";
import { ReactComponent as PendingSVG } from "../../../assets/images/pending.svg";

const EmailVerificationInfo = () => {
  return (
    <section className="email-verification-info">
      <PendingSVG />
      <h3>Please verify your email</h3>
      <p>
        We’ve sent you a verification email to your registered email address.
      </p>
      <p>Please verify your email before continuing.</p>
    </section>
  );
};

export default EmailVerificationInfo;
