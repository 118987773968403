import "./Footer.scss";
import { ReactComponent as FooterLogo } from "../../assets/images/footer-logo.svg";
// import Button from "../Button";
import { Link } from "react-router-dom";
import {
  HOME,
  LOGIN,
  REGISTER,
  TERMSANDCONDITION,
} from "../../navigation/CONSTANT";
const Footer = () => {
  const year: number = new Date().getFullYear();
  return (
    <footer className="footer">
      <div>
        <ul className="footer-links">
          <Link to={HOME}>
            <li className="footer-link">Home</li>
          </Link>
          <Link to={LOGIN}>
            <li className="footer-link">Log In</li>
          </Link>
          <Link to={REGISTER}>
            <li className="footer-link">Create Account</li>
          </Link>
        </ul>
      </div>
      <div>
        <ul className="footer-links">
          <Link to={TERMSANDCONDITION}>
            <li className="footer-link">Privacy Policy</li>
          </Link>
          <Link to={TERMSANDCONDITION}>
            <li className="footer-link">Terms & Conditions</li>
          </Link>
          <Link to={TERMSANDCONDITION}>
            <li className="footer-link">Cookies Policy</li>
          </Link>
        </ul>
      </div>
      {/* <div className="feedback">
        <Link to="/feedback">
          <Button type="button" size="size-lg" colorTheme="has-primary-theme">
            Your Feedback
          </Button>
        </Link>
      </div> */}
      <div className="footer-logo-container">
        <FooterLogo />
        <p>@{year} Interactive Pharma Solutions</p>
      </div>
    </footer>
  );
};

export default Footer;
