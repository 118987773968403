export const registrationInitialValues = {
  profileImage: null,
  fullName: "",
  seniority: "Academic Clinical Fellow",
  speciality: "Acute Internal Medicine",
  region: "Afghanistan",
  professionalNumber: "",
  email: "",
  mobileNumber: "",
  password: "",
  confirmPassword: "",
  compliance: [],
};
