import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ActivityCard from "../../../../../components/ActivityCard";
import Button from "../../../../../components/Button";
import { useActPreferenceContext } from "../../../../../context/ActivityPreferenceContext";
import { OT } from "../../../../../navigation/CONSTANT";
import actPreferenceService from "../../../../../services/activityPreference";
import "./ActivityMenu.scss";

const ActivityMenu = () => {
  const { actPreference, setActPreference } = useActPreferenceContext();
  const history = useHistory();
  const goToOppThankYou = () => {
    history.push(OT);
  };
  useEffect(() => {
    const getActivityPreferences = async () => {
      const payload = await actPreferenceService.getActPreferences();
      setActPreference({ type: "INIT_ACT_PREF", payload });
    };
    getActivityPreferences();
  }, [setActPreference]);
  return (
    <div className="activity-menu">
      <h3 className="activity-menu-title">
        please choose activities that you would like to participate in
      </h3>
      <div className="activity-menu-container">
        {actPreference.map((activity: any) => (
          <ActivityCard
            key={activity.activityTypeId}
            activityTypeId={activity.activityTypeId}
            name={activity.name}
            description={activity.description}
            optInStatus={activity.optInStatus}
          />
        ))}
        <Button
          type="button"
          size="size-xxlg"
          colorTheme="has-primary-theme"
          onClick={goToOppThankYou}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default ActivityMenu;
