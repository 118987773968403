import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import { OptionType } from "./Select";
const CheckboxGroup = (props: any) => {
  const { label, name, options, ...rest } = props;
  return (
    <div className="form-control">
      <label>{label}</label>
      <Field name={name}>
        {({ field }: { field: any }) => {
          return options.map((option: OptionType) => {
            return (
              <div className="checkbox-option" key={option.key}>
                <input
                  type="checkbox"
                  id={option.value}
                  {...field}
                  {...rest}
                  value={option.value}
                  checked={field.value.includes(option.value)}
                />
                <label htmlFor={option.value}>{option.key}</label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

export default CheckboxGroup;
