export const complianceTypes = [
  {
    value: "hcp",
    key: "I can confirm that I am a registered and practicing Healthcare Professional",
  },
  {
    value: "termscondition",
    key: "I agree with the Terms and Conditions",
  },
  {
    value: "privpolicy",
    key: "I agree with the Privacy Policy",
  },
];
