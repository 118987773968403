import "./OpportunityCard.scss";
const OpportunityCard = () => {
  return (
    <div className="opportunity-card">
      <h3>NEW OPPORTUNITIES</h3>
      <p>There are no new opportunities at this time.</p>
    </div>
  );
};

export default OpportunityCard;
