import "./RegistrationHeading.scss";
const RegistrationHeading = () => {
  return (
    <section className="reg-heading">
      <h1>Registration &amp; Profiling</h1>
      <p>
        Before we begin, please choose the type of account you wish to set up.
      </p>
    </section>
  );
};

export default RegistrationHeading;
