import React, { createContext, useContext, useReducer } from "react";
import { actPreferenceReducer, initialActPref } from "./ActPreferenceReducer";

interface AppContextInterface {
  actPreference: any;
  setActPreference: React.Dispatch<any>;
}
const ActPreferenceContext = createContext<AppContextInterface>(
  {} as AppContextInterface
);

export const ActPreferenceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [actPreference, setActPreference] = useReducer(
    actPreferenceReducer,
    initialActPref
  );

  return (
    <ActPreferenceContext.Provider value={{ actPreference, setActPreference }}>
      {children}
    </ActPreferenceContext.Provider>
  );
};

export const useActPreferenceContext = () => useContext(ActPreferenceContext);
