import DashboardHeading from "./DashboardHeading";
import "./DashboardContent.scss";
import DashboardBody from "./DashboardBody";

const DashboardContent = () => {
  return (
    <main className="dashboard-content">
      <DashboardHeading />
      <DashboardBody />
    </main>
  );
};

export default DashboardContent;
