import React, { useState, createContext, useContext } from "react";
import actPreferenceService from "../../services/activityPreference";

interface AppContextInterface {
  auth: any;
  setAuth: React.Dispatch<any>;
}
const authContext = createContext<AppContextInterface>(
  {} as AppContextInterface
);

export function ProvideAuth(props: any) {
  const { children } = props;
  const [auth, setAuth] = useState(() => {
    const loggedUser = window.localStorage.getItem("loggedSynInAppUser");
    if (loggedUser) {
      const user = JSON.parse(loggedUser);
      actPreferenceService.setToken(user.authToken);
      return user;
    }
    return null;
  });

  return (
    <authContext.Provider value={{ auth, setAuth }}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}
