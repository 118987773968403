import { BrowserRouter as Router } from "react-router-dom";
import { ActPreferenceProvider } from "./context/ActivityPreferenceContext";
import { ProvideAuth } from "./navigation/Auth/ProvideAuth";
import RouterConfig from "./navigation/RouterConfig";

const App = () => {
  return (
    <ProvideAuth>
      <ActPreferenceProvider>
        <Router>
          <RouterConfig />
        </Router>
      </ActPreferenceProvider>
    </ProvideAuth>
  );
};

export default App;
