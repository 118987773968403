import Footer from "../../components/Footer";
import Header from "../../components/Header";
import RegistrationSuccessInfo from "./RegistrationSuccessInfo";

const RegistrationSuccess = () => {
  return (
    <>
      <Header />
      <RegistrationSuccessInfo />
      <Footer />
    </>
  );
};

export default RegistrationSuccess;
