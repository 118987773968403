import { useHistory } from "react-router";
import Button from "../../../components/Button";
import { REGISTER } from "../../../navigation/CONSTANT";
import LoginForm from "../LoginForm";

import "./LoginContent.scss";
const LoginContent = () => {
  const history = useHistory();
  return (
    <main className="login-content">
      <section className="login-header">
        <h1>Login</h1>
      </section>
      <LoginForm />
      <section className="login-footer">
        <div>
          <p>Don’t have a login?</p>
          <Button
            type="button"
            size="size-sm"
            colorTheme="has-primary-theme"
            onClick={() => history.push(REGISTER)}
          >
            Register
          </Button>
        </div>
      </section>
    </main>
  );
};

export default LoginContent;
