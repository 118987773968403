export const initialActPref = [];

export const actPreferenceReducer = (state: any, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case "INIT_ACT_PREF":
      return [...payload];
    case "UPDATE_OPT_STATUS":
      return state.map((activity: any) => {
        if (activity.activityTypeId !== payload.activityTypeId) return activity;

        return { ...activity, optInStatus: !activity.optInStatus };
      });
    default:
      return state;
  }
};
