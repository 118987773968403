import { Link } from "react-router-dom";
import Button from "../Button";

interface NavigationItemType {
  type: string;
  navigateTo: string;
  label: string;
  handleClick?: () => Promise<void>;
  size?: string;
}

const NavigationItem = ({
  navigationProps,
  active,
}: {
  navigationProps: NavigationItemType;
  active: boolean;
}) => {
  const { type, navigateTo, label, size, handleClick } = navigationProps;
  return type === "normal" ? (
    <Link to={navigateTo}>
      <li className={`nav-item ${active ? "nav-item-active" : ""}`}>{label}</li>
    </Link>
  ) : (
    <Link to={navigateTo} onClick={handleClick}>
      <li className="nav-item">
        <Button type="button" size={size || ""} colorTheme="has-primary-theme">
          {label}
        </Button>
      </li>
    </Link>
  );
};

export default NavigationItem;
