import "./RegistrationForm.scss";
import { Formik, Form, ErrorMessage } from "formik";
import { useRef, useState } from "react";
import * as Yup from "yup";
import FormikControl from "../../../components/Form/FormikControl";
import ImagePreviewComponent from "../../../components/Form/ImagePreviewComponent";
import TextError from "../../../components/Form/TextError";
import { complianceTypes } from "./complianceTypes";
import { countryTypes } from "./countryTypes";
import { seniorityTypes } from "./seniorityTypes";
import { specialityTypes } from "./specialityTypes";
import Button from "../../../components/Button";
import axios from "axios";
import { useHistory } from "react-router";
import { EMAILVERIFICATIONREQ } from "../../../navigation/CONSTANT";
import ReCaptcha from "react-google-recaptcha";
import { registrationInitialValues } from "./initialValues";
import { registrationValidationSchema } from "./validationSchema";
import { errorInformation } from "./errorTypes";
import LoadingSpinner from "../../../components/LoadingSpinner";

const RegistrationForm = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const signUpURL = "/user/signup";
  const history = useHistory();
  const reCaptchaRef = useRef<ReCaptcha>(null);
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const seniorityOptions = seniorityTypes;
  const specialityOptions = specialityTypes;
  const regionOptions = countryTypes;
  const complianceOptions = complianceTypes;

  // to hide choose file
  const uploadFileRef: any = useRef(null);
  const initialValues: any = registrationInitialValues;
  const validationSchema: any = registrationValidationSchema;

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append("name", values.fullName);
    bodyFormData.append("seniority", values.seniority);
    bodyFormData.append("professionalNumber", values.professionalNumber);
    bodyFormData.append("speciality", values.speciality);
    bodyFormData.append("region", values.region);
    bodyFormData.append("email", values.email);
    bodyFormData.append("mobileNumber", values.mobileNumber);
    bodyFormData.append("password", values.password);
    if (values.profileImage) {
      bodyFormData.append("image", values.profileImage);
    }

    //recaptcha setting

    let reCaptchaToken;
    if (reCaptchaRef.current) {
      reCaptchaToken = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
    }

    axios({
      method: "post",
      url: `${baseUrl}${signUpURL}`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        "g-recaptcha-response": reCaptchaToken,
      },
    })
      .then((response) => {
        setLoading(false);
        history.push(EMAILVERIFICATIONREQ);
        actions.resetForm();
      })
      .catch((error) => {
        //handle error
        setLoading(false);
        setError(
          errorInformation[error?.response?.data?.errors] ||
            "Error in registering the user"
        );
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="reg-form">
          <div className="form-container">
            <Form className="register-form" onChange={() => setError("")}>
              <ReCaptcha
                ref={reCaptchaRef}
                sitekey={process.env.REACT_APP_Recaptcha_Site_key!}
                size="invisible"
              />
              <ImagePreviewComponent file={values.profileImage} />
              <div className="form-control">
                <input
                  hidden
                  ref={uploadFileRef}
                  type="file"
                  onChange={(event: any) => {
                    console.log("Event fired");
                    setFieldValue("profileImage", event.target.files[0]);
                  }}
                />
                <button
                  type="button"
                  onClick={() => {
                    uploadFileRef.current.click();
                  }}
                  className="upload-button"
                >
                  <p>Upload a photo</p>
                  <p>&#40;Optional&#41;</p>
                </button>
                <ErrorMessage component={TextError} name="profileImage" />
              </div>
              <FormikControl
                control="input"
                type="text"
                label="Full Name"
                name="fullName"
              />
              <FormikControl
                control="select"
                label="Seniority"
                name="seniority"
                options={seniorityOptions}
              />
              <FormikControl
                control="select"
                label="Speciality"
                name="speciality"
                options={specialityOptions}
              />
              <FormikControl
                control="select"
                label="Region"
                name="region"
                options={regionOptions}
              />
              <FormikControl
                control="input"
                type="text"
                label="Professional Number"
                name="professionalNumber"
              />
              <FormikControl
                control="input"
                type="email"
                label="Email Address"
                name="email"
              />
              <FormikControl
                control="input"
                type="tel"
                label="Mobile Number"
                name="mobileNumber"
              />
              <FormikControl
                control="password"
                type="password"
                label="Choose Password"
                name="password"
              />
              <FormikControl
                control="password"
                type="password"
                label="Confirm Password"
                name="confirmPassword"
              />
              <div className="compliance-container">
                <h3>Compliance</h3>
                <p>
                  Please read through our terms and conditions and privacy
                  policy
                </p>
              </div>
              <FormikControl
                control="checkbox"
                label=""
                name="compliance"
                options={complianceOptions}
              />
              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Create account
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default RegistrationForm;
