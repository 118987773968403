import { useState } from "react";
import defaultAvatar from "../../assets/images/defaultavatar.png";
import "./ImagePreview.scss";
const ImagePreviewComponent = ({ file }: { file: any }) => {
  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(
    null
  );
  if (file) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
  }
  return (
    <div className="previewImage">
      {typeof previewImage !== "string" ? (
        <img src={defaultAvatar} alt="preview" />
      ) : (
        <img src={previewImage} alt="preview" />
      )}
    </div>
  );
};

export default ImagePreviewComponent;
