import "./Card.scss";
const Card = ({
  title,
  actions,
}: {
  title: string;
  actions: Array<string>;
}) => {
  return (
    <div className="card">
      <h3>{title}</h3>
      {actions.map((action, index) => (
        <p key={`act ${index}`}>{action}</p>
      ))}
    </div>
  );
};

export default Card;
