import OpportunityCard from "../../../../../components/OpportunityCard";
import Card from "../../../../../components/Card";
import ProfileInfoCard from "../../../../../components/ProfileInfoCard";
import defaultAvatar from "../../../../../assets/images/defaultavatar.png";

import "./DashboardInfo.scss";
import { useAuth } from "../../../../../navigation/Auth/ProvideAuth";

const DashboardInfo = () => {
  const { auth } = useAuth();
  const { name, seniority, speciality, region, image } = auth?.userDetails;
  const profileInformation = {
    name,
    seniority,
    speciality,
    region,
    image: image || defaultAvatar,
  };
  return (
    <div className="dashboard-info">
      <ProfileInfoCard profileInformation={profileInformation} />
      <OpportunityCard />
      <Card
        title="Activity Preferences"
        actions={["Advisory Boards", "Steering Committees", "Clinical Trials"]}
      />
    </div>
  );
};

export default DashboardInfo;
