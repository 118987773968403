import "./OTHeading.scss";
const OTHeading = () => {
  return (
    <section className="ot-heading">
      <div className="title-container">
        <h1>Thank you</h1>
      </div>
    </section>
  );
};

export default OTHeading;
