import Footer from "../../components/Footer";
import Header from "../../components/Header";
import OTContent from "./OTContent";

const OpportunitesThankYou = () => {
  return (
    <>
      <Header />
      <OTContent />
      <Footer />
    </>
  );
};

export default OpportunitesThankYou;
