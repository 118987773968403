export const countryTypes = [
  { value: "ctry0", key: "Afghanistan" },
  { value: "ctry1", key: "Albania" },
  { value: "ctry2", key: "Algeria" },
  { value: "ctry3", key: "Andorra" },
  { value: "ctry4", key: "Angola" },
  { value: "ctry5", key: "Antigua & Deps" },
  { value: "ctry6", key: "Argentina" },
  { value: "ctry7", key: "Armenia" },
  { value: "ctry8", key: "Australia" },
  { value: "ctry9", key: "Austria" },
  { value: "ctry10", key: "Azerbaijan" },
  { value: "ctry11", key: "Bahamas" },
  { value: "ctry12", key: "Bahrain" },
  { value: "ctry13", key: "Bangladesh" },
  { value: "ctry14", key: "Barbados" },
  { value: "ctry15", key: "Belarus" },
  { value: "ctry16", key: "Belgium" },
  { value: "ctry17", key: "Belize" },
  { value: "ctry18", key: "Benin" },
  { value: "ctry19", key: "Bhutan" },
  { value: "ctry20", key: "Bolivia" },
  { value: "ctry21", key: "Bosnia Herzegovina" },
  { value: "ctry22", key: "Botswana" },
  { value: "ctry23", key: "Brazil" },
  { value: "ctry24", key: "Brunei" },
  { value: "ctry25", key: "Bulgaria" },
  { value: "ctry26", key: "Burkina" },
  { value: "ctry27", key: "Burundi" },
  { value: "ctry28", key: "Cambodia" },
  { value: "ctry29", key: "Cameroon" },
  { value: "ctry30", key: "Canada" },
  { value: "ctry31", key: "Cape Verde" },
  { value: "ctry32", key: "Central African Rep" },
  { value: "ctry33", key: "Chad" },
  { value: "ctry34", key: "Chile" },
  { value: "ctry35", key: "China" },
  { value: "ctry36", key: "Colombia" },
  { value: "ctry37", key: "Comoros" },
  { value: "ctry38", key: "Congo" },
  { value: "ctry39", key: "Congo {Democratic Rep}" },
  { value: "ctry40", key: "Costa Rica" },
  { value: "ctry41", key: "Croatia" },
  { value: "ctry42", key: "Cuba" },
  { value: "ctry43", key: "Cyprus" },
  { value: "ctry44", key: "Czech Republic" },
  { value: "ctry45", key: "Denmark" },
  { value: "ctry46", key: "Djibouti" },
  { value: "ctry47", key: "Dominica" },
  { value: "ctry48", key: "Dominican Republic" },
  { value: "ctry49", key: "East Timor" },
  { value: "ctry50", key: "Ecuador" },
  { value: "ctry51", key: "Egypt" },
  { value: "ctry52", key: "El Salvador" },
  { value: "ctry53", key: "Equatorial Guinea" },
  { value: "ctry54", key: "Eritrea" },
  { value: "ctry55", key: "Estonia" },
  { value: "ctry56", key: "Ethiopia" },
  { value: "ctry57", key: "Fiji" },
  { value: "ctry58", key: "Finland" },
  { value: "ctry59", key: "France" },
  { value: "ctry60", key: "Gabon" },
  { value: "ctry61", key: "Gambia" },
  { value: "ctry62", key: "Georgia" },
  { value: "ctry63", key: "Germany" },
  { value: "ctry64", key: "Ghana" },
  { value: "ctry65", key: "Greece" },
  { value: "ctry66", key: "Grenada" },
  { value: "ctry67", key: "Guatemala" },
  { value: "ctry68", key: "Guinea" },
  { value: "ctry69", key: "Guinea-Bissau" },
  { value: "ctry70", key: "Guyana" },
  { value: "ctry71", key: "Haiti" },
  { value: "ctry72", key: "Honduras" },
  { value: "ctry73", key: "Hungary" },
  { value: "ctry74", key: "Iceland" },
  { value: "ctry75", key: "India" },
  { value: "ctry76", key: "Indonesia" },
  { value: "ctry77", key: "Iran" },
  { value: "ctry78", key: "Iraq" },
  { value: "ctry79", key: "Ireland {Republic}" },
  { value: "ctry80", key: "Israel" },
  { value: "ctry81", key: "Italy" },
  { value: "ctry82", key: "Ivory Coast" },
  { value: "ctry83", key: "Jamaica" },
  { value: "ctry84", key: "Japan" },
  { value: "ctry85", key: "Jordan" },
  { value: "ctry86", key: "Kazakhstan" },
  { value: "ctry87", key: "Kenya" },
  { value: "ctry88", key: "Kiribati" },
  { value: "ctry89", key: "Korea North" },
  { value: "ctry90", key: "Korea South" },
  { value: "ctry91", key: "Kosovo" },
  { value: "ctry92", key: "Kuwait" },
  { value: "ctry93", key: "Kyrgyzstan" },
  { value: "ctry94", key: "Laos" },
  { value: "ctry95", key: "Latvia" },
  { value: "ctry96", key: "Lebanon" },
  { value: "ctry97", key: "Lesotho" },
  { value: "ctry98", key: "Liberia" },
  { value: "ctry99", key: "Libya" },
  { value: "ctry100", key: "Liechtenstein" },
  { value: "ctry101", key: "Lithuania" },
  { value: "ctry102", key: "Luxembourg" },
  { value: "ctry103", key: "Macedonia" },
  { value: "ctry104", key: "Madagascar" },
  { value: "ctry105", key: "Malawi" },
  { value: "ctry106", key: "Malaysia" },
  { value: "ctry107", key: "Maldives" },
  { value: "ctry108", key: "Mali" },
  { value: "ctry109", key: "Malta" },
  { value: "ctry110", key: "Marshall Islands" },
  { value: "ctry111", key: "Mauritania" },
  { value: "ctry112", key: "Mauritius" },
  { value: "ctry113", key: "Mexico" },
  { value: "ctry114", key: "Micronesia" },
  { value: "ctry115", key: "Moldova" },
  { value: "ctry116", key: "Monaco" },
  { value: "ctry117", key: "Mongolia" },
  { value: "ctry118", key: "Montenegro" },
  { value: "ctry119", key: "Morocco" },
  { value: "ctry120", key: "Mozambique" },
  { value: "ctry121", key: "Myanmar, {Burma}" },
  { value: "ctry122", key: "Namibia" },
  { value: "ctry123", key: "Nauru" },
  { value: "ctry124", key: "Nepal" },
  { value: "ctry125", key: "Netherlands" },
  { value: "ctry126", key: "New Zealand" },
  { value: "ctry127", key: "Nicaragua" },
  { value: "ctry128", key: "Niger" },
  { value: "ctry129", key: "Nigeria" },
  { value: "ctry130", key: "Norway" },
  { value: "ctry131", key: "Oman" },
  { value: "ctry132", key: "Pakistan" },
  { value: "ctry133", key: "Palau" },
  { value: "ctry134", key: "Panama" },
  { value: "ctry135", key: "Papua New Guinea" },
  { value: "ctry136", key: "Paraguay" },
  { value: "ctry137", key: "Peru" },
  { value: "ctry138", key: "Philippines" },
  { value: "ctry139", key: "Poland" },
  { value: "ctry140", key: "Portugal" },
  { value: "ctry141", key: "Qatar" },
  { value: "ctry142", key: "Romania" },
  { value: "ctry143", key: "Russian Federation" },
  { value: "ctry144", key: "Rwanda" },
  { value: "ctry145", key: "St Kitts & Nevis" },
  { value: "ctry146", key: "St Lucia" },
  { value: "ctry147", key: "Saint Vincent & the Grenadines" },
  { value: "ctry148", key: "Samoa" },
  { value: "ctry149", key: "San Marino" },
  { value: "ctry150", key: "Sao Tome & Principe" },
  { value: "ctry151", key: "Saudi Arabia" },
  { value: "ctry152", key: "Senegal" },
  { value: "ctry153", key: "Serbia" },
  { value: "ctry154", key: "Seychelles" },
  { value: "ctry155", key: "Sierra Leone" },
  { value: "ctry156", key: "Singapore" },
  { value: "ctry157", key: "Slovakia" },
  { value: "ctry158", key: "Slovenia" },
  { value: "ctry159", key: "Solomon Islands" },
  { value: "ctry160", key: "Somalia" },
  { value: "ctry161", key: "South Africa" },
  { value: "ctry162", key: "South Sudan" },
  { value: "ctry163", key: "Spain" },
  { value: "ctry164", key: "Sri Lanka" },
  { value: "ctry165", key: "Sudan" },
  { value: "ctry166", key: "Suriname" },
  { value: "ctry167", key: "Swaziland" },
  { value: "ctry168", key: "Sweden" },
  { value: "ctry169", key: "Switzerland" },
  { value: "ctry170", key: "Syria" },
  { value: "ctry171", key: "Taiwan" },
  { value: "ctry172", key: "Tajikistan" },
  { value: "ctry173", key: "Tanzania" },
  { value: "ctry174", key: "Thailand" },
  { value: "ctry175", key: "Togo" },
  { value: "ctry176", key: "Tonga" },
  { value: "ctry177", key: "Trinidad & Tobago" },
  { value: "ctry178", key: "Tunisia" },
  { value: "ctry179", key: "Turkey" },
  { value: "ctry180", key: "Turkmenistan" },
  { value: "ctry181", key: "Tuvalu" },
  { value: "ctry182", key: "Uganda" },
  { value: "ctry183", key: "Ukraine" },
  { value: "ctry184", key: "United Arab Emirates" },
  { value: "ctry185", key: "United Kingdom" },
  { value: "ctry186", key: "United States" },
  { value: "ctry187", key: "Uruguay" },
  { value: "ctry188", key: "Uzbekistan" },
  { value: "ctry189", key: "Vanuatu" },
  { value: "ctry190", key: "Vatican City" },
  { value: "ctry191", key: "Venezuela" },
  { value: "ctry192", key: "Vietnam" },
  { value: "ctry193", key: "Yemen" },
  { value: "ctry194", key: "Zambia" },
  { value: "ctry195", key: "Zimbabwe" },
];
