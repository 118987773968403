import OTMenu from "./OTMenu";
import "./OTBody.scss";
import DashboardInfo from "./DashboardInfo";

const OTBody = () => {
  return (
    <section className="ot-body">
      <DashboardInfo />
      <OTMenu />
    </section>
  );
};

export default OTBody;
