import { useAuth } from "../../navigation/Auth/ProvideAuth";
import {
  unauthenticatedNavItems,
  authenticatedNavItems,
} from "./navigationItemsList";
import NavigationItem from "./NavigationItem";
import { useProvideAuth } from "../../navigation/Auth/useProvideAuth";
import { useLocation } from "react-router";

interface NavigationItemType {
  type: string;
  navigateTo: string;
  label: string;
  handleClick?: () => Promise<void>;
  size?: string;
}

const NavigationMenu = () => {
  const { auth } = useAuth();
  const { signout } = useProvideAuth();
  const location = useLocation();

  const handleClick = async () => {
    await signout();
  };
  const navItems: Array<NavigationItemType> = auth?.authToken
    ? authenticatedNavItems
    : unauthenticatedNavItems;

  return (
    <ul className="nav-items">
      {navItems.map((navItem, index) => {
        if (navItem.label === "Log Out") {
          navItem = { ...navItem, handleClick };
        }

        return (
          <NavigationItem
            key={`navItem${index}`}
            navigationProps={navItem}
            active={navItem.navigateTo === location.pathname}
          />
        );
      })}
    </ul>
  );
};

export default NavigationMenu;
