import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const authenticateURL = "/auth/login";
interface CredentialType {
  email: string;
  password: string;
}
const login = async (credential: CredentialType, reCaptchaToken: any) => {
  const config = {
    headers: { "g-recaptcha-response": reCaptchaToken },
  };

  const response = await axios.post(
    `${baseUrl}${authenticateURL}`,
    credential,
    config
  );
  return response.data;
};

const loginService = { login };

export default loginService;
