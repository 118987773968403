import "./ActionCircle.scss";
interface ActionCircleType {
  title: string;
}
const ActionCircle = ({ title }: ActionCircleType) => {
  return (
    <div className="action-circle">
      <h3>
        Join <br /> Today
      </h3>
    </div>
  );
};

export default ActionCircle;
