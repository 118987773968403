import "./VerifyAccountInfo.scss";
import { ReactComponent as VerifiedSVG } from "../../../assets/images/verified.svg";
import { ReactComponent as PendingSVG } from "../../../assets/images/pending-email.svg";
import { ReactComponent as FailureSVG } from "../../../assets/images/failure-email.svg";
import Button from "../../../components/Button";
import { useHistory, useParams } from "react-router-dom";
import { LOGIN } from "../../../navigation/CONSTANT";
import { useEffect, useRef, useState } from "react";
import registerService from "../../../services/register";
import ReCaptcha from "react-google-recaptcha";

type VerificationState = "SUCCESS" | "FAILURE" | "PENDING";

const VerifyAccountInfo = () => {
  const [status, setStatus] = useState<VerificationState>("PENDING");
  const { token } = useParams<{ token?: string }>();
  const reCaptchaRef = useRef<ReCaptcha>(null);
  console.log(token);
  const history = useHistory();
  console.log({ status });
  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (!token) return;
        let reCaptchaToken;
        if (reCaptchaRef.current) {
          reCaptchaToken = await reCaptchaRef.current.executeAsync();
          reCaptchaRef.current.reset();
        }
        const verifyTokenResp = await registerService.verifyEmail(
          token,
          reCaptchaToken
        );
        console.log({ verifyTokenResp });
        setStatus("SUCCESS");
      } catch (error: any) {
        console.log({ error });
        setStatus("FAILURE");
      }
    };

    verifyToken();
  }, [token]);

  switch (status) {
    case "FAILURE":
      return (
        <section className="email-verification-info">
          <ReCaptcha
            ref={reCaptchaRef}
            sitekey={process.env.REACT_APP_Recaptcha_Site_key!}
            size="invisible"
          />
          <FailureSVG />
          <h3>Account Verification Failed</h3>
          <p>We could not verify the identify of the user.</p>
        </section>
      );
    case "SUCCESS":
      return (
        <section className="email-verification-info">
          <ReCaptcha
            ref={reCaptchaRef}
            sitekey={process.env.REACT_APP_Recaptcha_Site_key!}
            size="invisible"
          />
          <VerifiedSVG />
          <h3>Account Verified</h3>
          <p>Your account has been successfully verified</p>
          <Button
            type="button"
            size="size-xxlg"
            colorTheme="has-primary-theme"
            onClick={() => history.push(LOGIN)}
          >
            Continue
          </Button>
        </section>
      );
    default:
      return (
        <section className="email-verification-info">
          <ReCaptcha
            ref={reCaptchaRef}
            sitekey={process.env.REACT_APP_Recaptcha_Site_key!}
            size="invisible"
          />
          <PendingSVG />
          <h3>Verifying Email</h3>
          <p>Please wait while your account is verified</p>
        </section>
      );
  }
};

export default VerifyAccountInfo;
