import "./Header.scss";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import NavigationMenu from "./NavigationMenu";
import { useState } from "react";
const MobileNavigation = () => {
  const [open, setOpen] = useState(false);
  const openIcon = (
    <AiOutlineMenu
      className="menu-icon"
      size="40px"
      onClick={() => setOpen(!open)}
    />
  );

  const closeIcon = (
    <AiOutlineClose
      className="menu-icon"
      size="40px"
      onClick={() => setOpen(!open)}
    />
  );
  return (
    <nav className="navigation navigation-mobile">
      <div className="menu-icon-container">{open ? closeIcon : openIcon}</div>
      {open && <NavigationMenu />}
    </nav>
  );
};

export default MobileNavigation;
