import { Switch, Route, Redirect } from "react-router";
import AboutUs from "../pages/AboutUs";
import Dashboard from "../pages/Dashboard";
import EmailVerification from "../pages/EmailVerification";
import Landing from "../pages/Landing";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import OpportunitesThankYou from "../pages/OpportunitesThankYou";
import Registration from "../pages/Registration";
import RegistrationSuccess from "../pages/RegistrationSuccess";
import TermsCondition from "../pages/TermsCondition";
import VerifyAccount from "../pages/VerifyAccount";
import PrivateRoute from "./Auth/PrivateRoute";
import { useAuth } from "./Auth/ProvideAuth";
import {
  ROOT,
  REGISTER,
  REGISTERSUCCESS,
  LOGIN,
  DASHBOARD,
  ABOUTUS,
  TERMSANDCONDITION,
  OT,
  EMAILVERIFICATION,
  EMAILVERIFICATIONREQ,
} from "./CONSTANT";

const RouterConfig = () => {
  const { auth } = useAuth();
  const isLoggedIn: boolean = auth && auth.authToken;

  return (
    <Switch>
      {/* Public Routes */}
      <Route exact path={ROOT} component={Landing} />
      <Route exact path={REGISTER}>
        {isLoggedIn ? <Redirect to={DASHBOARD} /> : <Registration />}
      </Route>
      <Route exact path={LOGIN}>
        {isLoggedIn ? <Redirect to={DASHBOARD} /> : <Login />}
      </Route>
      <Route exact path={LOGIN}>
        {isLoggedIn ? <Redirect to={OT} /> : <Login />}
      </Route>
      <Route exact path={REGISTERSUCCESS}>
        {isLoggedIn ? <Redirect to={DASHBOARD} /> : <RegistrationSuccess />}
      </Route>
      <Route exact path={EMAILVERIFICATIONREQ}>
        {isLoggedIn ? <Redirect to={DASHBOARD} /> : <EmailVerification />}
      </Route>
      <Route exact path={EMAILVERIFICATION}>
        {isLoggedIn ? <Redirect to={DASHBOARD} /> : <VerifyAccount />}
      </Route>

      {/* Private Routes */}
      <PrivateRoute exact path={DASHBOARD}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute exact path={OT}>
        <OpportunitesThankYou />
      </PrivateRoute>

      {/* Static Pages */}
      <Route exact path={ABOUTUS} component={AboutUs} />
      <Route exact path={TERMSANDCONDITION} component={TermsCondition} />

      {/* No match found */}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default RouterConfig;
