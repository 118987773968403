import Button from "../../../components/Button";
import ActionCircle from "../ActionCircle";
import OpportunityIcon from "../OpportunityIcon";
import { ReactComponent as AdvIcon } from "../../../assets/images/adv-board.svg";
import { ReactComponent as RecruitIcon } from "../../../assets/images/recruitment.svg";
import { ReactComponent as ResIcon } from "../../../assets/images/research.svg";
import { ReactComponent as SteerIcon } from "../../../assets/images/steer-comm.svg";
import { ReactComponent as AvtDoctor } from "../../../assets/images/avt-doctor.svg";
import { Link } from "react-router-dom";
import "./LandingContent.scss";
import { useAuth } from "../../../navigation/Auth/ProvideAuth";

const LandingContent = () => {
  const { auth } = useAuth();
  const isLoggedIn: boolean = auth && auth.authToken;
  return (
    <main className="landing-content">
      <section className="hero">
        <div className="hero-content">
          <h1 className="tag">
            Be part of ONE OF the fastest growing global research communitIES
          </h1>
          <h1 className="objective">
            connectING Health care professionals with the Pharmaceutical
            Industry
          </h1>
          {!isLoggedIn && (
            <Link to="/register">
              <ActionCircle title="Join Today" />
            </Link>
          )}
        </div>
      </section>
      <section className="info">
        <div className="info-content">
          <p>
            THE GLOBAL RESEARCH COMMUNITY (TGRC) is one of the fastest growing
            digital research, data and insights gathering communities, providing
            the Pharmaceutical Industry with data and insights into critical
            questions that will help shape and direct therapeutics development
            now and in the future.
          </p>
          <p>
            By registering and becoming a member of the Community your profile
            will be matched with research opportunities sponsored by the top 20
            global pharmaceutical companies. These will include <br />
            Digital Advisory Boards, Steering Committees, Market Research, and
            Clinical Trials Recruitment.
          </p>
          <h4>
            In return for your participation you will be paid an Honorarium at a
            rate based on local guidelines.
          </h4>
        </div>
      </section>
      <section className="opportunities">
        <div className="opp-container">
          <OpportunityIcon
            opptitle="Advisory
boards"
          >
            <AdvIcon />
          </OpportunityIcon>
          <OpportunityIcon
            opptitle="STEERING
          COMMITTEES"
          >
            <SteerIcon />
          </OpportunityIcon>
          <OpportunityIcon
            opptitle="market
          research"
          >
            <ResIcon />
          </OpportunityIcon>
          <OpportunityIcon
            opptitle="clinical trials 
          recruitment"
          >
            <RecruitIcon />
          </OpportunityIcon>
        </div>
      </section>
      {/* <section className="action">
        <div className="action-content">
          <ActionCircle title="Register" />
          <ActionCircle title="Profile" />
          <ActionCircle title="Connect" />
        </div>
      </section> */}
      <section className="location">
        <div className="location-btn-container">
          {/* <Button type="button" size="size-lg" colorTheme="has-primary-theme">
            <AvtDoctor />
            18,653 New Members
          </Button> */}
        </div>
      </section>
    </main>
  );
};

export default LandingContent;
