import {
  REGISTER,
  LOGIN,
  HOME,
  OPPORTUNITY,
  LOGOUT,
  ABOUTUS,
} from "../../navigation/CONSTANT";

interface NavigationItemType {
  type: string;
  navigateTo: string;
  label: string;
  handleClick?: () => Promise<void>;
  size?: string;
}

export const unauthenticatedNavItems: Array<NavigationItemType> = [
  { type: "normal", navigateTo: REGISTER, label: "Register" },
  { type: "normal", navigateTo: ABOUTUS, label: "About Us" },
  {
    type: "button",
    navigateTo: LOGIN,
    label: "Join / Log In",
    size: "size-sm",
  },
];
export const authenticatedNavItems: Array<NavigationItemType> = [
  { type: "normal", navigateTo: HOME, label: "Home" },
  { type: "normal", navigateTo: OPPORTUNITY, label: "Opportunities" },
  {
    type: "button",
    navigateTo: LOGOUT,
    label: "Log Out",
    size: "size-sm",
  },
];
