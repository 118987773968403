import "./ProfileInfoCard.scss";
interface ProfileInfoCardType {
  name: string;
  seniority: string;
  speciality: string;
  region: string;
  image?: string;
}
const ProfileInfoCard = ({
  profileInformation,
}: {
  profileInformation: ProfileInfoCardType;
}) => {
  const { name, seniority, speciality, region, image } = profileInformation;

  return (
    <div className="profile-info-card">
      <div className="profile-img-container">
        <img src={image} alt="profile" />
      </div>
      <h4>{name}</h4>
      <p>
        {seniority} | {speciality}
      </p>
      <p>Region: {region}</p>
    </div>
  );
};

export default ProfileInfoCard;
