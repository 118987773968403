export const seniorityTypes = [
  {
    value: "sen0",
    key: "Academic Clinical Fellow",
  },
  {
    value: "sen1",
    key: "Adviser/director",
  },
  {
    value: "sen2",
    key: "Anatomy demonstrator",
  },
  {
    value: "sen3",
    key: "Assistant Professor",
  },
  {
    value: "sen4",
    key: "Associate Medical Director",
  },
  {
    value: "sen5",
    key: "Associate Professor",
  },
  {
    value: "sen6",
    key: "Associate specialist",
  },
  {
    value: "sen7",
    key: "Clinical assistant",
  },
  {
    value: "sen8",
    key: "Clinical Lecturer",
  },
  {
    value: "sen9",
    key: "Clinical Research Physician",
  },
  {
    value: "sen10",
    key: "Clinical Teaching Fellow",
  },
  {
    value: "sen11",
    key: "Consultant",
  },
  {
    value: "sen12",
    key: "Fixed Term Specialist Training Appointment (FTSTA)",
  },
  {
    value: "sen13",
    key: "Foundation 1 (academic)",
  },
  {
    value: "sen14",
    key: "Foundation 1 (PRHO)",
  },
  {
    value: "sen15",
    key: "Foundation 2",
  },
  {
    value: "sen16",
    key: "Foundation 2 (academic)",
  },
  {
    value: "sen17",
    key: "Foundation 2/ Senior House Officer",
  },
  {
    value: "sen18",
    key: "Further degree student",
  },
  {
    value: "sen19",
    key: "General Duties Medical Officer (Military)",
  },
  {
    value: "sen20",
    key: "GP In training (years 1 & 2)",
  },
  {
    value: "sen21",
    key: "GP In training (years 3 & 4)",
  },
  {
    value: "sen22",
    key: "GP Partner/Principal",
  },
  {
    value: "sen23",
    key: "Honorary Consultant / Lecturer",
  },
  {
    value: "sen24",
    key: "Honorary Consultant / Senior Lecturer",
  },
  {
    value: "sen25",
    key: "Hospital practitioner",
  },
  {
    value: "sen26",
    key: "Hospital Trust GP",
  },
  {
    value: "sen27",
    key: "Locum Appointment for Service (LAS)",
  },
  {
    value: "sen28",
    key: "Locum Appointment for Training (LAT)",
  },
  {
    value: "sen29",
    key: "MD/PhD student",
  },
  {
    value: "sen30",
    key: "Medical Adviser",
  },
  {
    value: "sen31",
    key: "Medical Director",
  },
  {
    value: "sen32",
    key: "Non Principal GP",
  },
  {
    value: "sen33",
    key: "Other academic posts",
  },
  {
    value: "sen34",
    key: "Pharmacovigilance Physician",
  },
  {
    value: "sen35",
    key: "Portfolio GP",
  },
  {
    value: "sen36",
    key: "Post-CCT Clinical Fellowship",
  },
  {
    value: "sen37",
    key: "Private practitioner",
  },
  {
    value: "sen38",
    key: "Professor",
  },
  {
    value: "sen39",
    key: "Research Associate",
  },
  {
    value: "sen40",
    key: "Research Fellow",
  },
  {
    value: "sen41",
    key: "Retainer GP",
  },
  {
    value: "sen42",
    key: "Retired",
  },
  {
    value: "sen43",
    key: "Returning GP",
  },
  {
    value: "sen44",
    key: "Salaried GP",
  },
  {
    value: "sen45",
    key: "Senior Clinical Fellow",
  },
  {
    value: "sen46",
    key: "Senior Clinical Research Physician",
  },
  {
    value: "sen47",
    key: "Senior house officer",
  },
  {
    value: "sen48",
    key: "Senior Lecturer",
  },
  {
    value: "sen49",
    key: "Senior Medical Adviser",
  },
  {
    value: "sen50",
    key: "Senior Pharmacovigilance Physician",
  },
  {
    value: "sen51",
    key: "Senior registrar",
  },
  {
    value: "sen52",
    key: "Sessional GP",
  },
  {
    value: "sen53",
    key: "Specialist registrar/LAT/FTSTA",
  },
  {
    value: "sen54",
    key: "Specialist Trainee 1 (or equivalent)",
  },
  {
    value: "sen55",
    key: "Specialist Trainee 2 (or equivalent)",
  },
  {
    value: "sen56",
    key: "Specialist Trainee 3 (or equivalent)",
  },
  {
    value: "sen57",
    key: "Specialist Trainee 6",
  },
  {
    value: "sen58",
    key: "Specialist Trainee/Registrar 4",
  },
  {
    value: "sen59",
    key: "Specialist Trainee/Registrar 5",
  },
  {
    value: "sen60",
    key: "Specialist Trainee/Registrar 6",
  },
  {
    value: "sen61",
    key: "Specialist Trainee/Registrar 7",
  },
  {
    value: "sen62",
    key: "Specialist Trainee/Registrar 8",
  },
  {
    value: "sen63",
    key: "Specialty Doctor",
  },
  {
    value: "sen64",
    key: "Staff grade",
  },
  {
    value: "sen65",
    key: "Subspecialty trainee",
  },
  {
    value: "sen66",
    key: "Tribunal Member",
  },
  {
    value: "sen67",
    key: "Trust Grade",
  },
  {
    value: "sen68",
    key: "Visiting lecturer",
  },
];
