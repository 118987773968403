import React, { FunctionComponent } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
interface LoadingSpinnerProps {
  isLoading: boolean;
}

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({
  isLoading,
}: LoadingSpinnerProps) => {
  // loading indicator styling
  const override = css`
    display: flex;
    justify-content: center;
    margin-top: 20px;
  `;
  return (
    <BeatLoader
      color={"#55ada5"}
      loading={isLoading}
      css={override}
      size={24}
    />
  );
};

export default LoadingSpinner;
