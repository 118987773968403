import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";

import "./TermsConditionContent.scss";
const TermsConditionContent = () => {
  return (
    <main className="terms-condition">
      <section className="terms-condition-header">
        <h3>T&C’s, Privacy Policy and Cookies Notice</h3>
      </section>
      <section className="terms-condition-body">
        <div className="content-ct">
          <div className="content-section">
            <h4 className="heading">1. Introduction</h4>
            <p>
              1.1. This privacy policy aims to inform you about how Interactive
              Pharma Solutions Limited, which operates The Global Research
              Community, (referred to as “IPS”, “TGRC”, “we”, “us” or “our” in
              this privacy policy) processes your personal data and tells you
              about your privacy rights under data protection laws.
            </p>
            <p>
              1.2. For the purposes of EU data protection laws, we are the
              ‘controller’ of the personal data we collect about you through and
              in connection to our operation of the TGRC platform.
            </p>
            <p>
              1.3. TGRC incorporates privacy controls for members which affect
              how we will process your personal data. By using the privacy
              controls, you can specify your marketing and communications
              preferences and limit the publication of your information. You can
              access the privacy controls via
              <a
                href="https://www.TGRC.cloud/updateyourprofile/default.aspx"
                target="_blank"
              >
                https://www.TGRC.cloud/updateyourprofile/default.aspx
              </a>
              .
            </p>
          </div>
          <div className="content-section">
            <h4 className="heading">2. How we use your personal data</h4>
            <div className="sub-content">
              <h5 className="sub-heading">
                2.1. In this Section we have set out:
              </h5>
              <p>• the general categories of personal data that we process. </p>
              <p>
                • in the case of personal data that we did not obtain directly
                from you, the source and specific categories of that data.
              </p>
              <p>• the purposes for which we may process personal data; and </p>
              <p>• the legal bases of the processing.</p>
            </div>
            <div className="sub-content">
              <h5 className="sub-heading">2.2. Usage data:</h5>
              <p>
                We process data about your use of our website and services. The
                usage data may include your IP address, geographical location,
                browser type and version, operating system, referral source,
                length of visit, page views and website navigation paths, as
                well as information about the timing, frequency and pattern of
                your service use. The source of the usage data is our analytics
                tracking systems and system logs. We process usage data for the
                purposes of analysing the use of the website and services and
                serving targeted content and invitations to participate in
                relevant market research activities to you. The legal basis for
                this processing is our legitimate interests, namely monitoring
                and improving our website and services and the performance of
                market research services on behalf of our clients.
              </p>
            </div>
            <div className="sub-content">
              <h5 className="sub-heading">2.3. Account data:</h5>
              <p>
                We process personal data that you provide when registering to
                become a TGRC member and set up a TGRC account. Your account
                data comprises some personal data that is mandatory in order to
                join TGRC and set up an account and some personal data that is
                optional once you have become a member and set up an account. We
                may also link the information you provide via your account with
                additional data sets provided by third parties for verification
                and internal reporting purposes. We will also use this data for
                the purposes of creating an account for you, authenticating
                access to your account, identifying you as the account holder,
                controlling and enabling you to access areas of our website and
                services that are restricted to members and enabling you to
                reset your password and gain access to your account if you
                forget your username or password. The legal basis for this
                processing is our legitimate interests, namely ensuring the
                security of our network, IT systems and information and
                providing TGRC members with a means of accessing our website and
                services.
              </p>
            </div>

            <div className="sub-content">
              <div className="sub-heading">
                2.4 Use of your account data for market research purposes:We
                will use your mandatory account data for the purposes of:
                <p style={{ marginTop: "20px" }}>
                  • inviting you to take part in digital advisory boards and
                  market research activities relevant to your specialty and
                  seniority.
                </p>
                <p style={{ marginBottom: "20px" }}>
                  • monitoring and enabling your participation in research
                  activities via our website, to ensure that you can only
                  participate in the same activity once and that any incentives
                  offered for taking part in such activities are correctly
                  credited to you.
                </p>
                <p>
                  This use of your data involves an element of profiling and
                  automated decision making, in that we screen our members
                  (using automated means) because of their specialty and
                  seniority so that only members falling within the specialty
                  and seniority criteria specified by our clients for specific
                  market research activities are invited to participate in those
                  activities. This screening process is necessary in order to
                  ensure that our clients are able to comply with their legal
                  obligations relevant to their host country.
                  <p>
                    We may invite you to participate in relevant research
                    activities by email or by using messages delivered to your
                    homepage when you login to your TGRC account.
                  </p>
                  <p>
                    You can opt-out of receiving these email invitations at any
                    time and indicate whether you would like to receive
                    invitations via text or telephone call by changing your
                    communication preferences at
                    <a
                      href="https://www.TGRC.cloud/updateyourprofile/default.aspx"
                      target="_blank"
                    >
                      https://www.TGRC.cloud/updateyourprofile/default.aspx
                    </a>
                  </p>
                  <p style={{ marginTop: "20px" }}>
                    The legal basis for this processing is our legitimate
                    interests, namely providing research services to our
                    clients, and the legitimate interests of our clients in
                    obtaining research data about their market, products or
                    businesses in compliance with legal obligations to which
                    they are subject. Where incentives are offered in return for
                    taking part, the legal basis for our processing of your
                    account data for the purposes of crediting you with those
                    incentives will be performance of a contract.
                  </p>
                </p>
              </div>
            </div>
            <div className="sub-content">
              <h5 className="sub-heading">
                2.5 Use of your account data for marketing and communications
                purposes:
              </h5>
              <p style={{ marginBottom: "20px" }}>
                We will use your account data to send marketing and
                communications that are relevant to our products and services.
              </p>
              <p>
                You can opt-out from any specific communications by using the
                unsubscribe facility in the communication itself or via the TGRC
                website. We may also prompt you from time to time to revisit and
                update your communication preferences with the aim of ensuring
                that we only send you relevant and wanted communications.
              </p>
            </div>

            <div className="sub-content">
              <h5 className="sub-heading">
                2.6 Data collected as a result of your participation in research
                activities:
              </h5>
              <p>
                If you take part in any research activities, we will process
                some of your account data as necessary to enable you to
                participate in the research.
              </p>
              <p>
                We will also process the information given by you in response to
                the research questions (“research data”).
              </p>
              <p>
                We may disclose certain personal data about you to the client
                for whom we are conducting the research in the following
                circumstances: if your responses include any information
                pertaining to the safety of, or an adverse event connected with,
                a pharmaceutical product (an “adverse event”), we are required
                by law and under our contract with the client to report such
                adverse event to the client, we will always gain your consent
                before passing any Personal Data to our Client. The client will
                then be required by law to take certain actions in respect of
                the adverse event. This may include contacting you to obtain
                further information about the adverse event.
              </p>
            </div>
            <div className="sub-content">
              <h5 className="sub-heading">2.7 Enquiry data:</h5>
              <p>
                We will process information contained in any enquiry you submit
                to us regarding products and/or services advertised on our
                website, such as when you complete a form requesting more
                information. We will process your enquiry data for the purposes
                of responding to your enquiry, and if you request to be
                contacted directly by a relevant third-party organisation, we
                will pass the contact details you used to make your enquiry to
                the relevant third-party organisation for this purpose. The
                legal basis for this processing is our legitimate interests in
                communicating with our members and our clients’ legitimate
                interests in contacting our members where requested by an
                individual member.
              </p>
            </div>
            <div className="sub-content">
              <h5 className="sub-heading">2.8 Correspondence data:</h5>
              <p>
                We will process information contained in or relating to any
                communication that you send to us. The correspondence data
                provided via email or telephone calls may be processed for the
                purposes of communicating with you, logging your communication
                through our Member Services system, and for the management of
                your account. The legal basis for this processing is our
                legitimate interests, namely the proper administration of our
                website and business and communications with users.
              </p>
            </div>
            <div className="sub-content">
              <h5 className="sub-heading">
                2.9 Using your personal data for other purposes:
              </h5>
              <p>
                In addition to the specific purposes for which we may process
                your personal data set out in this Section 2, we may also
                process any of your personal data where such processing is
                necessary for:
                <p>
                  o compliance with a legal obligation to which we are subject;
                </p>
                <p>
                  o protecting your vital interests or the vital interests of
                  another natural person;
                </p>
                <p>
                  o obtaining or maintaining insurance coverage, managing risks,
                  or obtaining professional advice; and/or
                </p>
                <p>
                  o the establishment, exercise or defence of legal claims,
                  whether in court proceedings or in an administrative or
                  out-of-court procedure.
                </p>
              </p>
            </div>
            <div className="sub-content">
              <h5 className="sub-heading">
                2.10 Other peoples’ personal data:
              </h5>
              <p>
                Please do not supply any other person’s personal data to us
                unless we prompt you to do so.
              </p>
            </div>
          </div>
          <div className="content-section">
            <h4 className="heading">3. Automated decision-making</h4>
            <p>
              3.1 We will use your relevant account data for the purposes of
              automated decision-making in relation to deciding what research
              you will be invited to participate in. This will usually require
              an element of screening against your specialty and seniority for
              the purposes of delivering relevant and suitable content and
              invitations. This is described in more detail in section 2.
            </p>
            <p>
              3.2 The significance and possible consequences of this automated
              decision-making are that it will affect what content you will see
              on our site or receive and what invitations to participate in
              research you will receive.
            </p>
            <p>
              3.3 We encourage you to keep your account data up to date, to
              ensure that the content and invitations delivered to you are
              relevant and suitable.
            </p>
          </div>
          <div className="content-section">
            <h4 className="heading">
              4. Providing your personal data to others
            </h4>
            <p>
              4.1 We may disclose your personal data to any member of our group
              of companies (this means our subsidiaries, our ultimate holding
              company and all its subsidiaries) insofar as reasonably necessary
              for the purposes set out in this policy.
            </p>
            <p>
              4.2 We may disclose your personal data to our insurers and/or
              professional advisers insofar as reasonably necessary for the
              purposes of obtaining and maintaining insurance coverage, managing
              risks, obtaining professional advice and managing legal disputes.
            </p>
            <p>
              4.3 We may disclose your profile data to our suppliers or
              subcontractors insofar as reasonably necessary in order to deliver
              and/or evaluate key parts of our website services. We may disclose
              part or all of your data to selected third parties who supply
              additional services under the TGRC brand.
            </p>
            <p>
              4.4 Financial transactions relating to our website and services
              may be handled by our payment service providers. We will share
              transaction data with these service providers only to the extent
              necessary for the purposes of processing your payments, refunding
              such payments and dealing with complaints or queries.
            </p>
            <p>
              4.5 We may disclose your enquiry data to one or more of those
              selected third-party organisations identified on our website, for
              the purposes of the third-party organisations offering
              participation in research to you.
              <div className="sub-content">
                <h5
                  className="sub-heading"
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  Categories of these third-party organisations are as follows:
                </h5>
                <p>• Pharmaceutical companies</p>
                <p>• Other companies in the healthcare industry</p>
                <p>• Medical device companies </p>
                <p>• Financial services companies </p>
                <p>• Medical indemnity organisations </p>
                <p>• Medical jobs boards</p>
                <p>• Charities</p>
                <p
                  style={{
                    maxWidth: "646px",
                    margin: "20px auto",
                  }}
                >
                  Each such third party will act as a data controller in
                  relation to the enquiry data that we supply to it; and such
                  third party’s own privacy policy will govern that third
                  party's use of your personal data.
                </p>
              </div>
            </p>
            <p>
              4.6 In addition to the specific disclosures of personal data set
              out in this Section 4, we may also disclose your personal data
              where such disclosure is necessary for compliance with a legal
              obligation to which we are subject, or in order to protect your
              vital interests or the vital interests of another natural person.
            </p>
          </div>
          <div className="content-section">
            <h4 className="heading">
              5. International transfers of your personal data
            </h4>
            <p>
              5.1 In this Section 5, we provide information about the
              circumstances in which your personal data may be transferred to
              countries outside the UK
            </p>
            <p>
              5.2 Where we are required to transfer data to our other group
              companies as listed in section 4, we will ensure all personal data
              is protected by appropriate safeguards, namely the use of standard
              data protection clauses adopted or approved by the European
              Commission and/or EU-US Privacy Shield and Switzerland-US Privacy
              Shield. For further details please contact us.
            </p>
            <p>
              5.3 The hosting facilities for our website are situated in the UK.
            </p>
            <p>
              5.4 You acknowledge that personal data that you submit for
              publication through our website or services may be available, via
              the internet, around the world. We cannot prevent the use (or
              misuse) of such personal data by others.
            </p>
          </div>
          <div className="content-section">
            <h4 className="heading">6. Retaining and deleting personal data</h4>
            <p>
              6.1 This Section 6 sets out our data retention policies and
              procedure, which are designed to help ensure that we comply with
              our legal obligations in relation to the retention and deletion of
              personal data.
            </p>
            <p>
              6.2 Personal data that we process for any purpose or purposes
              shall not be kept for longer than is necessary for that purpose or
              those purposes.
            </p>
            <div className="sub-content">
              <h5
                className="sub-heading"
                style={{
                  marginBottom: "20px",
                }}
              >
                6.3 We will retain and delete your personal data as follows:
              </h5>
              <p>
                (a) Your account data, financial transaction data and
                correspondence data may be deleted after three years from the
                date on which your account has become inactive. (For the
                definition of ‘inactive’, see our Terms and Conditions for
                details)
              </p>
              <p>
                (b) Your usage data and market research data are anonymised and
                will be retained.
              </p>
            </div>

            <p>
              6.4 Notwithstanding the other provisions of this Section 6, we may
              retain your personal data where such retention is necessary for
              compliance with a legal obligation to which we are subject, or in
              order to protect your vital interests or the vital interests of
              another natural person.
            </p>
          </div>
          <div className="content-section">
            <h4 className="heading">7. Security of personal data</h4>
            <p>
              7.1 We will take appropriate technical and organisational
              precautions and apply industry best practice to secure your
              personal data and to prevent the loss, misuse or alteration of
              your personal data.
            </p>
            <p>
              7.2 We will store all your personal data on secure servers,
              personal computers and mobile devices.
            </p>
            <p>
              7.3 Data relating to your enquiries and financial transactions
              that is sent from your web browser to our web server, or from our
              web server to your web browser, will be protected using encryption
              technology.
            </p>
            <p>
              7.4 You acknowledge that the transmission of unencrypted (or
              inadequately encrypted) data over the internet is inherently
              insecure, and we cannot guarantee the security of data sent over
              the internet.
            </p>
            <p>
              7.5 You should ensure that your password is not susceptible to
              being guessed, whether by a person or a computer program. You are
              responsible for keeping the password you use for accessing our
              website confidential and your password will only be used when you
              log in to our website. We will never ask you for your password.
            </p>
          </div>
          <div className="content-section">
            <h4 className="heading">8. Amendments</h4>
            <p>
              8.1 We may update this policy from time to time by publishing a
              new version on our website.
            </p>
            <p>8.2 This Privacy Policy was last updated on 19 January 2022.</p>
          </div>
          <div className="content-section">
            <h4 className="heading">9. Your rights</h4>
            <p>
              9.1 In this Section 9, we have summarised the rights that you have
              under data protection law. Accordingly, you should read the
              relevant laws and guidance from the regulatory authorities for a
              full explanation of these rights.
            </p>

            <div className="rights">
              <p>9.2 Your principal rights under data protection law are:</p>
              <p> (a) the right to access.</p>
              <p> (b) the right to rectification.</p>
              <p> (c) the right to erasure. </p>
              <p>(d) the right to restrict processing.</p>
              <p> (e) the right to object to processing.</p>
              <p> (f) the right to data portability.</p>
              <p> (g) the right to complain to a supervisory authority; and</p>
              <p> (h) the right to withdraw consent.</p>
            </div>
            <p>
              9.3 You have the right to confirmation as to whether we process
              your personal data and, where we do, access to the personal data,
              together with certain additional information. That additional
              information includes details of the purposes of the processing,
              the categories of personal data concerned and the recipients of
              the personal data. Providing the rights and freedoms of others are
              not affected, we will supply to you a copy of your personal data,
              after you supplied appropriate evidence of your identity (this
              will require you to log in). The first copy will be provided free
              of charge, but additional copies may be subject to a reasonable
              fee. You can access your account data by visiting
              <a href="https://personal-data.TGRC.cloud/" target="_blank">
                https://personal-data.TGRC.cloud/
              </a>
              when logged into the website.
            </p>
            <p>
              9.4 You have the right to have any inaccurate personal data about
              you rectified and, taking into account the purposes of the
              processing, to have any incomplete personal data about you
              completed. You can update and complete your account data yourself
              on the ‘Update Your Profile’ page on the website.
            </p>
            <p>
              9.5 In some circumstances, you have the right to the erasure of
              your personal data. These circumstances include: the personal data
              are no longer necessary in relation to the purposes for which they
              were collected or otherwise processed; you withdraw consent to
              consent- based processing; the processing is for direct marketing
              purposes; and the personal data have been unlawfully processed.
              However, there are certain general exclusions of the right to
              erasure. Those general exclusions include where processing is
              necessary for exercising the right of freedom of expression and
              information and for compliance with a legal obligation, or for the
              establishment, exercise or defence of legal claims.
            </p>
            <p>
              9.6 In some circumstances, you have the right to restrict the
              processing of your personal data. These circumstances are you
              contest the accuracy of the personal data; processing is unlawful,
              but you oppose erasure; we no longer need the personal data for
              the purposes of our processing, but you require personal data for
              the establishment, exercise or defence of legal claims; and you
              have objected to processing, pending the verification of that
              objection. Where processing has been restricted on this basis, we
              may continue to store your personal data. However, we will only
              otherwise process it: with your consent; for the establishment,
              exercise or defence of legal claims; for the protection of the
              rights of another natural or legal person; or for reasons of
              important public interest.
            </p>
            <p>
              9.7 You have the right to object to our processing of your
              personal data on grounds relating to your situation, but only to
              the extent that the legal basis for the processing is that the
              processing is necessary for: the performance of a task carried out
              in the public interest or in the exercise of any official
              authority vested in us; or the purposes of the legitimate
              interests pursued by us or by a third party. If you make such an
              objection, we will cease to process the personal information
              unless we can demonstrate compelling legitimate grounds for the
              processing which override your interests, rights and freedoms, or
              the processing is for the establishment, exercise or defence of
              legal claims. If you object specifically to the processing of your
              account data, then fulfilment of this request will require the
              termination of your membership of TGRC
            </p>
            <p>
              9.8 You have the right to object to our processing of your
              personal data for direct marketing purposes (including profiling
              for direct marketing purposes). If you make such an objection, we
              will cease to process your personal data for this purpose.
            </p>
            <p>
              9.9 You have the right to object to our processing of your
              personal data for scientific or historical research purposes or
              statistical purposes on grounds relating to your situation, unless
              the processing is necessary for the performance of a task carried
              out for reasons of public interest.
            </p>
            <p>
              9.10 To the extent that the legal basis for our processing of your
              personal data is consent, and such processing is carried out by
              automated means, you have the right to receive your personal data
              from us in a structured, commonly used and machine-readable
              format. However, this right does not apply where it would
              adversely affect the rights and freedoms of others.
            </p>
            <p>
              9.11 If you consider that our processing of your personal
              information infringes data protection laws, you have a legal right
              to lodge a complaint with a supervisory authority responsible for
              data protection. You may do so in the EU member state of your
              habitual residence, your place of work or the place of the alleged
              infringement.
            </p>
            <p>
              9.12 To the extent that the legal basis for our processing of your
              personal information is consent, you have the right to withdraw
              that consent at any time. Withdrawal will not affect the
              lawfulness of processing before the withdrawal.
            </p>
            <p>
              9.13 You may exercise any of your rights in relation to your
              personal data by contacting our Member Services team by telephone
              or email.
            </p>
          </div>
          <div className="content-section">
            <h4 className="heading">10. Third party websites</h4>
            <p style={{ marginBottom: "0px" }}>
              10.1 Our website includes hyperlinks to, and details of, third
              party websites.
            </p>
            <p>
              10.2 We have no control over, and are not responsible for, the
              privacy policies and practices of third parties.
            </p>
          </div>
          <div className="content-section">
            <h4 className="heading">11. Personal data of children</h4>
            <p style={{ marginBottom: "0px" }}>
              11.1 Our website and services are targeted at persons over the age
              of 18.
            </p>
            <p>
              11.2 If we have reason to believe that we hold personal data of a
              person under that age in our databases, we will delete that
              personal data.
            </p>
          </div>
          <div className="content-section">
            <h4 className="heading">12. Updating information</h4>
            <p>
              12.1 Please let us know if the personal information that we hold
              about you needs to be corrected or updated. You can also update
              this on the ‘Update your profile’ section of the website.
            </p>
          </div>
          <div className="content-section">
            <h4 className="heading">13. Cookies that we use</h4>
            <p>
              13.1 Blocking all cookies will have a negative impact upon the
              usability of many websites.
            </p>
            <p>
              13.2 If you block cookies, you will not be able to use all the
              features on our website.
            </p>
            <div className="sub-content">
              <h5 className="sub-heading" style={{ marginBottom: "20px" }}>
                13.3 We use cookies for the following purposes:
              </h5>
              <p>
                (a) authentication - we use cookies to identify you when you
                visit our website, if you are logged in, as you navigate our
                website, and to protect our website and services generally.
              </p>
              <p>
                (b) personalisation - we use cookies to store information about
                your preferences and to personalise our website for you.
              </p>
              <p>
                (c) advertising - we use cookies to help us to display
                advertisements that will be relevant to you.
              </p>
              <p>
                (d) analysis - we use cookies to help us to analyse the use and
                performance of our website and services.
              </p>
            </div>
          </div>
          <div className="content-section">
            <h4 className="heading">14. Our Details</h4>
            <p>14.1 This website is owned and operated by IPS Ltd.</p>
            <p>
              14.2 IPS Ltd is registered in England and Wales under registration
              number 09245216, and our registered office is at The Wheelhouse,
              Angel Court, 81 St Clements Street, Oxford, United Kingdom, OX41AW
            </p>
            <p>
              14.3 Our principal place of business is The Wheelhouse, Angel
              Court, 81 St Clements Street, Oxford, United Kingdom, OX41AW
            </p>
            <div className="sub-content">
              <h5 className="sub-heading">14.4 You can contact us: </h5>
              <p>(a) by post, using the postal address given above.</p>
              <p>
                (b) by email, using
                <a href="mailto:help@TGRC.cloud" target="_blank">
                  help@TGRC.cloud
                </a>
              </p>
            </div>
          </div>
          <div className="content-section">
            <h4 className="heading">15. Data protection registration </h4>
            <p style={{ marginBottom: "0px" }}>
              15.1 We are registered as a data controller with the UK
              Information Commissioner's Office.
            </p>
            <p>
              15.2 Our data protection registration number is <i>ZB294253</i>
            </p>
          </div>
          <div className="content-section">
            <h4 className="heading">16. Data protection officer</h4>
            <p>
              16.1 We have appointed a data protection officer who can be
              contacted via
              <a href="mailto:DPO@TGRC.cloud" target="_blank">
                DPO@TGRC.cloud
              </a>
              .If you wish to complain about this privacy notice or any of the
              procedures set out in it, please contact our Data Protection
              Officer.
            </p>
            <p style={{ marginBottom: "0px" }}>
              16.2 You also have the right to raise concerns with Information
              Commissioner’s Office on 0303 123 1113 or at
              <a href="https://ico.org.uk/" target="_blank">
                https://ico.org.uk/
              </a>
            </p>
            <p>
              concerns, if you believe that your data protection rights have not
              been adhered to.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TermsConditionContent;
