import "./LoginForm.scss";
import { Formik, Form } from "formik";
import FormikControl from "../../../components/Form/FormikControl";
import * as Yup from "yup";
import Button from "../../../components/Button";
import { OPPORTUNITY, REGISTER } from "../../../navigation/CONSTANT";
import { Link, useHistory } from "react-router-dom";
import TextError from "../../../components/Form/TextError";
import { useRef, useState } from "react";
import { errorInformation } from "./errorTypes";
import { useProvideAuth } from "../../../navigation/Auth/useProvideAuth";
import ReCaptcha from "react-google-recaptcha";
import LoadingSpinner from "../../../components/LoadingSpinner";

const LoginForm = () => {
  const history = useHistory();
  const auth = useProvideAuth();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const reCaptchaRef = useRef<ReCaptcha>(null);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is Required"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = async (values: any, actions: any) => {
    setLoading(true);
    const credentials = {
      email: values.email,
      password: values.password,
    };
    let reCaptchaToken;
    if (reCaptchaRef.current) {
      reCaptchaToken = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
    }
    try {
      await auth.signin(credentials, reCaptchaToken);
      history.replace(OPPORTUNITY);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError(
        errorInformation[error?.response?.data?.errors] ||
          "Error in logging the user"
      );
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, isValid, setFieldValue }) => (
        <section className="login-form-content">
          <div className="login-form-container">
            <Form className="login-form" onChange={() => setError("")}>
              <ReCaptcha
                ref={reCaptchaRef}
                sitekey={process.env.REACT_APP_Recaptcha_Site_key!}
                size="invisible"
              />
              <FormikControl
                control="input"
                type="email"
                label="Email Address"
                name="email"
              />

              <FormikControl
                control="password"
                type="password"
                label="Password"
                name="password"
              />

              <Button
                type="submit"
                size="size-xxlg"
                disabled={!isValid}
                colorTheme="has-primary-theme"
              >
                Login
              </Button>
              <LoadingSpinner isLoading={isLoading} />
              {error && <TextError>{error}</TextError>}
              <Link to={REGISTER}>Forgot your password&#63;</Link>
            </Form>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default LoginForm;
