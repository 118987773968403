import OTHeading from "./OTHeading";
import "./OTContent.scss";
import OTBody from "./OTBody";

const OTContent = () => {
  return (
    <main className="ot-content">
      <OTHeading />
      <OTBody />
    </main>
  );
};

export default OTContent;
