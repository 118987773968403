import ActivityMenu from "./ActivityMenu";
import "./DashboardBody.scss";
import DashboardInfo from "./DashboardInfo";

const DashboardBody = () => {
  return (
    <section className="dashboard-body">
      <DashboardInfo />
      <ActivityMenu />
    </section>
  );
};

export default DashboardBody;
