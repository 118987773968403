import Footer from "../../components/Footer";
import Header from "../../components/Header";
import LoginContent from "./LoginContent";

const Login = () => {
  return (
    <>
      <Header />
      <LoginContent />
      <Footer />
    </>
  );
};

export default Login;
