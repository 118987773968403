import RegistrationForm from "../RegistrationForm";

import RegistrationHeading from "../RegistrationHeading";
import "./RegistrationContent.scss";
const RegistrationContent = () => {
  return (
    <main className="registration-content">
      <RegistrationHeading />
      <RegistrationForm />
    </main>
  );
};

export default RegistrationContent;
