import Button from "../../../components/Button";
import { ReactComponent as NotFoundIcon } from "../../../assets/images/not-found.svg";
import "./NotFoundContent.scss";
import { useHistory } from "react-router";
import { HOME } from "../../../navigation/CONSTANT";
const NotFoundContent = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push(HOME);
  };
  return (
    <main className="not-found">
      <NotFoundIcon className="not-found-icon" />
      <h3>404: Page not found</h3>
      <p>
        The page you’re looking for has even been moved, or is no longer
        available.
      </p>
      <Button
        type="button"
        size="size-lg"
        colorTheme="has-primary-theme"
        onClick={handleClick}
      >
        Back to Home
      </Button>
    </main>
  );
};

export default NotFoundContent;
