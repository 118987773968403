import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const preferenceURL = "/activity/preferences";
const optINURL = "/activity/opt-in";
const optOUTURL = "/activity/opt-out";

type tokenType = string | null;

interface OptRequestBody {
  activityTypeId: number;
}

let token: tokenType = null;

const setToken = (newToken: tokenType) => {
  token = `bearer ${newToken}`;
};

const getActPreferences = async () => {
  const config = {
    headers: { Authorization: token },
  };
  const response = await axios.get(`${baseUrl}${preferenceURL}`, config);
  return response.data;
};

const activityOptIn = async (newObject: OptRequestBody) => {
  const config = {
    headers: { Authorization: token },
  };

  const response = await axios.post(`${baseUrl}${optINURL}`, newObject, config);

  return response.data;
};

const activityOptOut = async (newObject: OptRequestBody) => {
  const config = {
    headers: { Authorization: token },
  };

  const response = await axios.post(
    `${baseUrl}${optOUTURL}`,
    newObject,
    config
  );

  return response.data;
};

const actPreferenceService = {
  getActPreferences,
  setToken,
  activityOptIn,
  activityOptOut,
};
export default actPreferenceService;
