export const ROOT = "/";
export const LOGIN = "/login";
export const REGISTER = "/register";
export const REGISTERSUCCESS = "/registersuccess";
export const DASHBOARD = "/opportunities";
export const ABOUTUS = "/about-us";
export const CONTACTUS = "/contact-us";
export const OPPORTUNITY = "/opportunities";
export const MYPOINT = "/mypoint";
export const LOGOUT = "/";
export const FEEDBACK = "/feedback";
export const HOME = "/";
export const TERMSANDCONDITION = "/termsandcondition";
export const OT = "/opportunitiesthankyou";
export const EMAILVERIFICATION = "/verifyEmail/:token";
export const EMAILVERIFICATIONREQ = "/emailVerifyReq";
