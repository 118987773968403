import { useHistory } from "react-router";
import Button from "../../../components/Button";
import { LOGIN } from "../../../navigation/CONSTANT";
import "./RegistrationSuccessInfo.scss";

const RegistrationSuccessInfo = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push(LOGIN);
  };
  return (
    <section className="reg-success-info">
      <h2>Thank you!</h2>
      <p>You can now Login to your account.</p>
      <Button
        type="button"
        size="size-md"
        colorTheme="has-primary-theme"
        onClick={handleClick}
      >
        Log In
      </Button>
    </section>
  );
};

export default RegistrationSuccessInfo;
